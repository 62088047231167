<template>
	<!-- 工单管理-居民用户 -->
	<div>
		<div class="content">
			<div class="all" v-if="!dialogVisible">
				<div class="table">
					<!--搜索框-->
					<el-form
						ref="search"
						:inline="true"
						:model="search"
						size="small"
						label-width="80px"
					>
						<el-form-item label="用户分类">
							<!-- class="search-list" -->
							<el-select
								
								v-model="search.userTypeId"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
								
							>
							<!-- @change="loadList(1)" -->
								<el-option
									v-for="item in userTypes"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="部门">
							<el-select
								
								v-model="search.deptId"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in deptNamerData"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item label="片区">
							<el-cascader
								ref="pianqu"
								filterable
								
								clearable
								collapse-tags
								v-model="addrName"
								:options="taskAddrdata"
								:props="props"
								style="width: 215px"
							></el-cascader>
						</el-form-item>
						<el-form-item label="安检员">
							<el-select
								
								v-model="search.inspectorId"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in taskInspectorData"
									:key="item.id"
									:label="item.realname"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="隐患等级">
							<el-select
								
								v-model="search.level"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in levelData"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="隐患内容">
							<el-cascader
								ref="yinhuan"
								
								clearable
								v-model="entryType"
								:options="entryTypedata"
								collapse-tags
								:props="{
									value: 'trueId',
									label: 'name',
									checkStrictly: false,
									emitPath: false,
									multiple: true
								}"
							></el-cascader>
						</el-form-item>

						<el-form-item label="整改方式">
							<el-select
								
								v-model="search.woodenAbarbeitung"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in woodenAbarbeitungData"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="进度">
							<el-select
								
								v-model="search.woodenManage"
								clearable
								filterable
								multiple
								collapse-tags
								placeholder="请选择"
							>
								<el-option
									v-for="item in woodenManageData"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="检查时间">
							<el-date-picker
								style="width: 300px"
								v-model="times"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd HH:mm:ss"
								:default-time="['00:00:00', '23:59:59']"
							>
							</el-date-picker>
						</el-form-item>
						<el-form-item label="">
							<el-input
								placeholder="请输入关键字"
								v-model="search.keyWordValue"
							>
								<el-select
									placeholder="请选择"
									v-model="search.keyWord"
									slot="prepend"
								>
									<el-option
										v-for="item in searchBar"
										:key="item.id"
										:label="item.label"
										:value="item.id"
									></el-option>
								</el-select>
							</el-input>
						</el-form-item>

						<el-form-item label-width="20px">
							<div>
								<el-button
									type="primary"
									size="small"
									@click="loadList"
									>查询</el-button
								>
								<el-button
									type="warning"
									size="small"
									@click="reset"
									>重置</el-button
								>
							</div>
						</el-form-item>
					</el-form>
					<div style="display: flex; margin-bottom: 10px">
						<el-button
							v-if="$anthButtons.getButtonAuth('wtcl')"
							type="text"
							style="
								border: 1px solid #1082ff;
								color: #1082ff;
								padding: 0 14px;
								height: 30px;
							"
							plain
							@click="audit()"
							size="small"
							>处理</el-button
						>
						<el-button
							v-if="$anthButtons.getButtonAuth('zwx')"
							type="text"
							style="
								padding: 0 14px;
								height: 30px;"
							plain
							disabled
							size="small"
							>转维修</el-button
						>
						<!-- @click="handleZWX" style="
								border: 1px solid #1082ff;
								color: #1082ff;
								padding: 0 14px;
								height: 30px;
							"-->
						<el-button
							v-if="$anthButtons.getButtonAuth('wtdc')"
							type="text"
							style="
								border: 1px solid #1082ff;
								color: #1082ff;
								padding: 0 14px;
								height: 30px;
							"
							plain
							@click="exportUserExcel()"
							size="small"
							>导出</el-button
						>
					</div>
					<!--数据表格-->
					<el-table
						ref="tableData"
						:data="tableData"
						style="width: 100%"
						border
						height="calc(100vh - 450px)"
						stripe
						@sort-change="changeSort"
						:header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
						@selection-change="handleSelectionChange"
					>
						<el-table-column
							type="selection"
							width="50"
							fixed="left"
							:selectable="checkSelectable"
						>
						</el-table-column>
						<el-table-column type="index" label="序号" width="70">
						</el-table-column>
						<el-table-column
							prop="dataCode"
							width="170"
							label="工单编号"
						>
						</el-table-column>
						<el-table-column
							prop="taskName"
							label="任务名称"
							width="120"
						>
							<template slot-scope="{ row }">
								<el-tooltip
									class="item"
									effect="dark"
									:content="row.taskName"
									placement="top-start"
								>
									<div class="twoLines">
										{{ row.taskName }}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							prop="residentName"
							label="户主姓名"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="mobile"
							label="户主电话"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="userTypeName"
							label="用户分类"
							width="120"
						>
						</el-table-column>
						<el-table-column
							prop="addrName"
							label="片区"
							width="120"
						>
							<template slot-scope="{ row }">
								<el-tooltip
									class="item"
									effect="dark"
									:content="
										row.addrName + '->' + row.addrsName+ '->' + row.addrssName
									"
									placement="top-start"
								>
									<div class="twoLines">
										{{
											row.addrName + "->" + row.addrsName+ "->" + row.addrssName
										}}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							prop="address"
							label="地址"
							width="120"
						>
							<template slot-scope="{ row }">
								<el-tooltip
									class="item"
									effect="dark"
									:content="row.address"
									placement="top-start"
								>
									<div class="twoLines">
										{{ row.address }}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>

						<el-table-column
							prop="contactName"
							label="安检联系人"
							width="120"
						>
						</el-table-column>
						<el-table-column
							prop="contactMobile"
							label="联系电话"
							width="120"
						>
						</el-table-column>
						<el-table-column
							prop="deptName"
							label="负责部门"
							width="120"
						>
						</el-table-column>
						<el-table-column
							prop="inspectorName"
							label="安检员"
							width="120"
						>
						</el-table-column>

						<el-table-column
							prop="level"
							sortable="custom"
							label="隐患等级"
							width="120"
							fixed="right"
						>
							<template slot-scope="{ row }">
								<p :style="{ color: colorData[row.level] }">
									{{
										getDictLabel(
											levelData,
											row.level,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="content"
							label="隐患内容"
							width="120"
							fixed="right"
						>
							<template slot-scope="{ row }">
								<el-tooltip
									class="item"
									effect="dark"
									:content="row.content"
									placement="top-start"
								>
									<div class="twoLines">
										{{ row.content }}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							prop="woodenAbarbeitung"
							label="整改方式"
							width="120"
							fixed="right"
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											woodenAbarbeitungData,
											row.woodenAbarbeitung,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="woodenManage"
							label="处理结果"
							fixed="right"
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											woodenManageData,
											row.woodenManage,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>

						<el-table-column
							fixed="right"
							prop="checkTime"
							label="检查时间"
							width="160"
							sortable="custom"
						></el-table-column>

						<el-table-column
							fixed="right"
							prop="operation"
							label="操作"
							width="220"
							align="left"
						>
							<template slot-scope="{ row }">
								<el-button
									v-if="$anthButtons.getButtonAuth('wtck')"
									type="primary"
									size="small"
									@click="addItem(3, row)"
									>查看</el-button
								>
								<el-button
									v-if="$anthButtons.getButtonAuth('xgzgfs') && row.woodenManage==1"
									type="success"
									size="small"
									@click="changeZhenggaiWay(row)"
									>修改整改方式</el-button
								>
							</template>
						</el-table-column>
					</el-table>
					<div class="pageBox">
						<el-pagination
							:current-page="search.current"
							:background="true"
							:page-sizes="[30, 50, 100]"
							:page-size="search.size"
							layout="total, prev, pager, next, sizes, jumper"
							:total="total"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
						>
						</el-pagination>
					</div>
				</div>
				<div v-show="dialogVisible" style="height: 100%">
					<unDetail></unDetail>
				</div>
			</div>
		</div>
		<el-dialog
			:visible.sync="dialogBatch"
			width="400px"
			:close-on-click-modal="false"
			@close="handleClose"
		>
			<el-form class="SearchBox" ref="Batchform" :model="Batchform">
				<el-form-item label="处理结果:" label-width="90px" required>
					已处理
				</el-form-item>
				<el-form-item label="处理说明:" label-width="90px">
					<el-input
						type="textarea"
						:rows="2"
						placeholder="请输入内容"
						maxlength="200"
						show-word-limit
						v-model="Batchform.processingSpecification"
					>
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button
					style="margin-right: 10px"
					@click="dialogBatch = false"
					>取消</el-button
				>
				<el-button type="primary" @click="submitBatch('Batchform')"
					>确定</el-button
				>
			</span>
		</el-dialog>
		<!-- 选择维修人员 -->
		<el-dialog
			title="提交维修工单"
			:visible.sync="showeventDialog"
			:show-close="true"
			width="25%"
			:close-on-click-modal="false"
			@close="closeshowevent('texture')"
		>
			<el-form
				ref="texture"
				:model="texture"
				label-width="120px"
				:rules="rule"
			>
				<el-form-item label="受理部门：" prop="deptId">
					<el-cascader
						v-model="texture.deptId"
						:options="sectionList"
						:props="TreeProps"
						style="width: 100%"
						:show-all-levels="false"
						ref="cascaderHandle"
						@change="selectDepartment"
					>
						<span slot-scope="{ data }">
							{{ data.name }}
						</span>
					</el-cascader>
				</el-form-item>
				<el-form-item label="受理人：">
					<el-select
						clearable
						filterable
						style="width: 100%"
						v-model="texture.userId"
						placeholder="请选择受理人"
					>
						<el-option
							v-for="item in maintainUserList"
							:key="item.id"
							:label="item.realname"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="优先级：" prop="priorLevel">
					<el-radio-group v-model="texture.priorLevel">
						<el-radio
							:label="item.id"
							v-for="(item, index) in priorityList"
							:key="index"
							>{{ item.priorLevel }}</el-radio
						>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showeventDialog = false">取 消</el-button>
				<el-button type="primary" @click="submitService('form')"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>
 
<script>
	import {
		getTimeoutList,
		selectAllUsersByDeptId,
		transferRepair,
	} from "@/RequestPort/maintenance";
	import {
		getproblemPageList,
		taskInspectorList,
		addrDropDown1,
		getproblemBatchHandle,
		getdeptDropDown,
		getproblemExportExcel,
		getfindScuItemTreeEcho,
		findScuItemTreeEchoHaveStop,
		inspectorDropDown1,
		updateWoodenAbarbeitung
	} from "@/RequestPort/workorder/maintenRecord";
	import { selectDictListByPid } from "@/RequestPort/device/deviceList";
	import unDetail from "./component/details";
	import { getDictLabel } from "@/utils/common";
	import getNowFormatDate from "./../../common/js/nowDate.js";
	import { selectSysDeptTreeList } from "../../RequestPort/user/user";
	export default {
		components: { unDetail },
		data() {
			return {
				TreeProps: {
					label: "name",
					value: "id",
					children: "children",
					emitPath: false,
					checkStrictly: true
				},
				showeventDialog: false,
				texture: {
					priorLevel: "3",
				},
				// 受理部门
				sectionList: [],
				// 优先级
				priorityList: [],
				// 受理人员
				maintainUserList: [],
				getDictLabel,
				search: {
					current: 1,
					size: 100,
					keyWord: 5,
				},
				addrName: [],
				entryType: [],
				props: {
					value: "trueId",
					label: "name",
					checkStrictly: false,
					emitPath: false,
					multiple: true
				},
				// 审核表单数据
				Batchform: {
					processingSpecification: null,
				},
				// 审核弹窗
				dialogBatch: false,
				total: 0,
				dialogVisible: false,
				// 列表数据
				tableData: [],
				// 复选的选中数据
				checkAll: [],
				// 用户分类
				userTypes: [],
				// 片区下拉
				taskAddrdata: [],
				// 安检内容
				entryTypedata: [],
				// 安检员下拉
				taskInspectorData: [],
				deptNamerData: [],
				colorData: {
					1: "#FF0000",
					2: "#FF5E0F",
					3: "#FFA200",
					4: "#A167F0",
					5: "#4E7AEA",
					6: "#01EAB0",
					7: "#3FBA3D",
				},
				// 安检结果
				checkerList: [
					{ label: "正常安检", id: 1 },
					{ label: "到访不遇", id: 2 },
					{ label: "拒绝安检", id: 3 },
				],
				auditStatusList: [
					{ label: "未审核", id: 1 },
					{ label: "已审核", id: 2 },
					{ label: "驳回", id: 3 },
				],
				problemList: [
					{ label: "有问题未处理", id: "2" },
					{ label: "问题已处理", id: "3" },
					{ label: "无问题", id: "1" },
				],
				// 工单状态
				statusList: [
					{ label: "已完成", id: 1 },
					{ label: "未完成", id: 2 },
					{ label: "待复查", id: 3 },
				],
				levelData: [
					{ label: "1级", id: 1 },
					{ label: "2级", id: 2 },
					{ label: "3级", id: 3 },
					{ label: "4级", id: 4 },
					{ label: "5级", id: 5 },
					{ label: "6级", id: 6 },
					{ label: "7级", id: 7 },
				],
				woodenManageData: [
					{ label: "未处理", id: 1 },
					{ label: "已处理", id: 2 },
					{ label: "已转维修", id: 3 },
				],
				woodenAbarbeitungData: [
					{ label: "用户自行整改", id: 1 },
					{ label: "公司整改", id: 2 },
				],
				// 采集模板下拉
				taskTplIdData: [],
				// 关键字条件
				searchBar: [
					{ label: "户主名称", id: 1, name: "residentName" },
					{ label: "安检联系人", id: 2, name: "contactName" },
					{ label: "电话", id: 3, name: "mobile" },
					{ label: "地址", id: 4, name: "address" },
					{ label: "工单编号", id: 5, name: "dataCode" },
					{ label: "任务名称", id: 6, name: "taskName" },
				],
				rule: {
					deptId: [
						{
							required: true,
							message: "请选择受理部门",
							trigger: ["blur", "change"],
						},
					],
					priorLevel: [
						{
							required: true,
							message: "请选择优先级",
							trigger: "change",
						},
					],
				},
				// 时间
				times: [],
				// 导出弹窗
				editConductVisible: false,
				loading: true,
			};
		},
		mounted() {
			if (this.$route.query.taskCode) {
				this.search.taskCode = this.$route.query.taskCode;
			}
			// 获取列表数据
			this.loadList();
			// 用户分类
			this.getuserTypeDropDown();
			// 片区下拉
			this.gettaskAddrList();
			//部门下拉
			this.getdeptDropDownlsit();
			this.getentryTypedata();
			// 安检员下拉
			this.gettaskInspectorList();
		},
		methods: {
			changeZhenggaiWay(row) {
				this.$confirm('是否确认修改整改方式?', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					closeOnClickModal: false,
					type: "warning",
				}).then(() => {
					updateWoodenAbarbeitung({dataScuItemId: row.dataScuItemId}).then(res => {
						if (res.code === 200) {
							this.$message.success(res.data)
						}
						setTimeout(() => {
							this.loadList()
						}, 1000);
					})
				}).catch(() => {

				})
			},
			closeshowevent() {
				this.texture = {
					priorLevel: "3",
				};
				this.$refs.texture.resetFields();
			},
			submitService() {
				this.$refs.texture.validate(async (valid) => {
					if (valid) {
						let arr = [];
						this.checkAll.forEach((item) => {
							arr.push(item.dataScuItemId);
						});
						this.texture.dataScuItemId = arr;
						try {
							let res = await transferRepair(this.texture)
							this.showeventDialog = false;
							this.$confirm(res.msg || res.data, "提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								closeOnClickModal: false,
								type: "warning",
							});
							if (res.code == 200) {
								this.loadList();
							}
						} catch (e) {
							if (e.code == -303) {
								this.loadList();
								this.showeventDialog = false;
							}
						}
						// transferRepair(this.texture).then((res) => {
						// 	this.showeventDialog = false;
						// 	this.$confirm(res.msg || res.data, "提示", {
						// 		confirmButtonText: "确定",
						// 		cancelButtonText: "取消",
						// 		closeOnClickModal: false,
						// 		type: "warning",
						// 	});
						// 	if (res.code == 200) {
						// 		this.loadList();
						// 	}
						// });
					}
				});
			},
			/**
			 * 选择部门
			 */
			selectDepartment(val) {
				// 通过部门id查此部门及其下所有部门用户（人员下拉）
				selectAllUsersByDeptId({ deptId: val, code: "aj006" }).then(
					(res) => {
						this.maintainUserList = res.data;
					}
				);
			},
			handleZWX() {
				let that = this;
				if (this.checkAll.length == 0) {
					that.$message.error("请先勾选数据");
					return;
				}
				this.showeventDialog = true;
				// 部门下拉
				selectSysDeptTreeList({ deptListShow: "n" }).then((e) => {
					let tree = e.data;
					// if (tree[0]) {
					// 	tree[0].disabled = true;
					// }
					// // 特殊处理
					// if (tree[0].children) {
					// 	tree[0].children.forEach((el) => {
					// 		if (el.id == 8) {
					// 			el.disabled = true;
					// 		}
					// 	});
					// }
					this.sectionList = tree;
				});
				// 优先级
				getTimeoutList().then((res) => {
					this.priorityList = res.data;
				});
			},
			fillterName(name) {
				return name.split("(");
			},
			close(val) {
				this.dialogVisible = !this.dialogVisible; //监听值发生变化就关闭它
			},
			/**
			 * 重置
			 */
			reset() {
				this.search = {
					current: 1,
					size: 100,
					keyWord: 5,
				};
				this.times = [];
				this.addrName = [];
				this.entryType = [];
				this.loadList();
			},
			/**
			 * 部门下拉
			 */
			getdeptDropDownlsit() {
				getdeptDropDown().then((res) => {
					this.deptNamerData = res.data;
				});
			},
			/**
			 * 安检员下拉
			 */
			gettaskInspectorList() {
				inspectorDropDown1().then((res) => {
					this.taskInspectorData = res.data;
				});
			},
			/**
			 * 片区下拉
			 */
			gettaskAddrList() {
				addrDropDown1().then((res) => {
					this.taskAddrdata = res.data;
				});
			},
			// 安检内容
			getentryTypedata() {
				findScuItemTreeEchoHaveStop().then((res) => {
					this.entryTypedata = res.data.data;
				});
			},
			/***
			 * 用户分类
			 */
			getuserTypeDropDown() {
				selectDictListByPid({ pid: 732 }).then((res) => {
					this.userTypes = res.data;
				});
			},

			/**
			 * 更换条数
			 */
			handleSizeChange(val) {
				this.search.current = 1;
				this.search.size = val;
				this.loadList();
			},
			/**
			 * 更换页码
			 */
			handleCurrentChange(val) {
				this.search.current = val;
				this.loadList();
			},
			handleSelectionChange(val) {
				this.checkAll = val;
			},
			checkSelectable(row) {
				return row.woodenManage == 1;
			},
			// 关闭审核弹窗
			handleClose() {
				this.Batchform = { processingSpecification: null };
				this.dialogBatch = false;
			},
			audit() {
				let that = this;
				if (this.checkAll.length == 0) {
					that.$message.error("请先勾选数据");
					return;
				}
				this.dialogBatch = true;
			},
			// 提交审核
			submitBatch(form) {
				this.$refs[form].validate((valid) => {
					if (!valid) return false;
					let arr = [];
					this.checkAll.forEach((item) => {
						arr.push(item.dataScuItemId);
					});
					this.Batchform.dataScuItemIds = arr;
					getproblemBatchHandle(this.Batchform)
						.then((res) => {
							if (res.code === 200) {
								this.$message.success(res.data);
								this.loadList();
							}
							this.dialogBatch = false;
						})
						.catch((e) => {
							this.loadList();
							this.dialogBatch = false;
						});
				});
			},
			changeSort(val) {
				let sort = null;
				this.search.field =
					val.prop == "checkTime" ? "check_time" : val.prop;
				if (val.order == "descending") sort = "desc";
				if (val.order == "ascending") sort = "asc";
				this.search.sort = sort;
				if (!val.order) {
					delete this.search.field;
					delete this.search.sort;
				}
				this.loadList();
			},
			/**
			 * 获取列表数据
			 */
			loadList(val) {
				let searchObj = {}
				if (val) {
					this.search.current = 1;
				}
				// 关键字处理
				this.searchBar.forEach((el) => {
					if (el.id == this.search.keyWord) {
						this.$set(this.search, el.name, this.search.keyWordValue);
					} else {
						delete this.search[el.name];
					}
				});
				// 片区处理
				if (this.addrName && this.addrName.length > 0) {
					this.search.addrssId = this.$refs.pianqu.getCheckedNodes(true).map(item => {
						return item.data.trueId
					})
					// console.log(this.addrName)
					// if (this.addrName.length >= 2) {
					// 	this.search.addrssId = this.addrName[1];
					// 	delete this.search.addrId;
					// } else {
					// 	this.search.addrId = this.addrName[0];
					// 	delete this.search.addrssId;
					// }
				} else {
					delete this.search.addrssId;
					delete this.search.addrId;
				}
				// 隐患内容
				if (this.entryType && this.entryType.length > 0) {
					this.search.scuItemId = this.$refs.yinhuan.getCheckedNodes(true).map(item => {
						return item.data.trueId
					})
					// if (this.entryType.length >= 2) {
					// 	this.search.scuItemId = this.entryType[1];
					// 	delete this.search.entryType;
					// } else {
					// 	this.search.entryType = this.entryType[0];
					// 	delete this.search.scuItemId;
					// }
				} else {
					delete this.search.entryType;
					delete this.search.scuItemId;
				}
				// 时间处理
				if (this.times && this.times.length > 0) {
					this.search.checkTimeStart = this.times[0];
					this.search.checkTimeEnd = this.times[1];
				} else {
					delete this.search.checkTimeStart;
					delete this.search.checkTimeEnd;
				}
				for(let key in this.search) {
					//  && key != 'addrId' && key != 'addrssId'&& key != 'scuItemId' && key != 'entryType'
					if (key != 'size' && key != 'current' && key != 'keyWord' && key != 'periodIds') {
						searchObj[key] = this.search[key] && this.search[key].length ? this.search[key].toString() : '';
						// console.log(String(this.search[key]))
					} else {
						searchObj[key] = this.search[key]
					}
				}
				getproblemPageList(searchObj).then((res) => {
					this.tableData = res.data.records;
					this.total = res.data.total;
				});
			},
			exportUserExcel() {
				this.searchBar.forEach((el) => {
					if (el.id == this.search.keyWord) {
						this.$set(this.search, el.name, this.search.keyWordValue);
					} else {
						delete this.search[el.name];
					}
				});
				// 片区处理
				if (this.addrName && this.addrName.length > 0) {
					if (this.addrName.length >= 2) {
						this.search.addrssId = this.addrName[1];
						delete this.search.addrId;
					} else {
						this.search.addrId = this.addrName[0];
						delete this.search.addrssId;
					}
				} else {
					delete this.search.addrssId;
					delete this.search.addrId;
				}
				// 隐患内容
				if (this.entryType && this.entryType.length > 0) {
					if (this.entryType.length >= 2) {
						this.search.scuItemId = this.entryType[1];
						delete this.search.entryType;
					} else {
						this.search.entryType = this.entryType[0];
						delete this.search.scuItemId;
					}
				} else {
					delete this.search.entryType;
					delete this.search.scuItemId;
				}
				// 时间处理
				if (this.times && this.times.length > 0) {
					this.search.checkTimeStart = this.times[0];
					this.search.checkTimeEnd = this.times[1];
				} else {
					delete this.search.checkTimeStart;
					delete this.search.checkTimeEnd;
				}
				let searchObj = {}
				for(let key in this.search) {
					//  && key != 'addrId' && key != 'addrssId'&& key != 'scuItemId' && key != 'entryType'
					if (key != 'size' && key != 'current' && key != 'keyWord' && key != 'periodIds') {
						searchObj[key] = this.search[key] && this.search[key].length ? this.search[key].toString() : '';
						// console.log(String(this.search[key]))
					} else {
						searchObj[key] = this.search[key]
					}
				}
				console.log(searchObj)
				getproblemExportExcel(searchObj).then((res) => {
					if (res) {
						const blob = new Blob([res], { type: "application/x-xls" });
						// const fileName = "民用用户数据.xls";
						const fileName = "my" + getNowFormatDate() + ".xls";
						if ("download" in document.createElement("a")) {
							// 非IE下载
							const elink = document.createElement("a");
							elink.download = fileName;
							elink.style.display = "none";
							elink.href = URL.createObjectURL(blob);
							document.body.appendChild(elink);
							elink.click();
							URL.revokeObjectURL(elink.href); // 释放URL 对象
							document.body.removeChild(elink);
						} else {
							// IE10+下载
							navigator.msSaveBlob(blob, fileName);
						}
					}
				});
			},
			/**
			 * 查看页面
			 */
			addItem(index, row) {
				// this.$router.push({
				// 	path: "/home/workorder/civilDetails",
				// 	query: {
				// 		dataId: row.dataId,
				// 		type: row.finishType == 3 ? 1 : 0,
				// 		taskId: row.taskId,
				// 		residentId: row.residentId,
				// 		statusFlag: row.statusFlag,
				// 		buttonShow: 1,
				// 	},
				// });
				const routeData = this.$router.resolve({
					path: '/home/workorder/civilDetails',      //跳转目标窗口的地址
					query: {
						dataId: row.dataId,
						type: row.finishType == 3 ? 1 : 0,
						taskId: row.taskId,
						residentId: row.residentId,
						statusFlag: row.statusFlag,
						buttonShow: 1,
					},
				})
				window.open(routeData.href);
			},
		},
	};
</script>
 
<style scoped lang="less">
	::v-deep .el-input-group__prepend {
		width: 67px;
	}
	.search-list {
		width: 170px;
	}
	.table {
		padding: 10px;
		border-radius: 5px;
		margin: 10px;
		background-color: #ffffff;
	}
	.all {
		background-color: #f3f4f8;
		height: auto;
	}
	.content {
		background: #f3f4f8;
		width: 100%;
		border-radius: 6px;
		height: calc(100vh - 150px);
	}
</style>